import { Button, Col, Form, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storeLoadingSavingGsuBaseline, UpdateGsuHistoryBaseline, updateSumTotalForecast, updateTotalGSU } from '../../Redux/Reducers/Baseline'
import {
    LoadingOutlined
} from '@ant-design/icons'

const ModalUpdateGsuTon = (
{
    openModal=false,
    setOpenModal=()=>{},
    dataPeriodo,
    setDataPeriodo,
    rex_filter_data_baseline
}) => {

	const { 
        rex_info_status_dashboard,
        rex_loading_saving_gsu
	} = useSelector(state => state.baseline)

    let [ savingGsu, setSavingGsu ] = useState(false)

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const onFinishForm = async (values) => {

        // setSavingGsu(true)
        console.log("Antes de actualizar")
        // dispatch(storeLoadingSavingGsuBaseline(true))
        // await sleep(3000)
        // dispatch(updateSumTotalForecast({value: values.gsuNew, month: dataPeriodo.month}))
        const responseUpdate = dispatch(updateTotalGSU({ value: Number(values.gsuNew), month: dataPeriodo.month, year: dataPeriodo.year, name: dataPeriodo.name }))
        // dispatch(UpdateGsuHistoryBaseline({ value: Number(values.gsuNew), month: dataPeriodo.month, year: dataPeriodo.year, name: dataPeriodo.name }))
        // dispatch(storeLoadingSavingGsuBaseline(false))
        console.log("Después de actualizar")

        // setSavingGsu(false)
        
        setOpenModal(false)
        form.resetFields()
    }

    function formattedNumber(value){

		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(value);
	}

    useEffect(() => {

    }, [rex_loading_saving_gsu])
    return (
        <Modal
            title={<div className='ModalNewProduct__Title'>Actualizar GSU/TON</div>} 
            open={openModal}
            onCancel={() => {
                setDataPeriodo({})
                setOpenModal(false)
            }}
            footer={null}
            width={550}
        >
            {
                rex_info_status_dashboard.code == "OnExecution"
                ? <div><LoadingOutlined /> En estos momentos el <b>Deck</b> y <b>AoC</b> se encuentran actualizandose con los últimos cambios realizados.</div>
                :   <Form
                        form={form}
                        name="modalUpdateGsu"
                        onFinish={onFinishForm}
                        layout='vertical'
                    >
                        <Row gutter={[12,12]}>
                            <Col span={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                Actual: {formattedNumber(dataPeriodo.valuePrev)}
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Nuevo:'
                                    name='gsuNew'
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{display:'flex', justifyContent:'end', gap:'10px'}}>
                                {rex_loading_saving_gsu ? 'Guardando': 'Guardar'}
                                <Button danger>Cancelar</Button>
                                <Button 
                                    loading={rex_loading_saving_gsu}
                                    type='primary'
                                    htmlType="submit"
                                >{rex_loading_saving_gsu ? 'Guardando': 'Guardar'}</Button>
                            </Col>
                        </Row>
                    </Form>
            }
            
        </Modal>
    )
}

export default ModalUpdateGsuTon