// const api_url = 'http://127.0.0.1:8010/protected/'
// const api_url = 'https://back-pilotoso.grow-solutions.com/protected/'
const api_url = 'https://back-syop.grow-corporate.com/protected/'
export default {
    api_url : api_url,
    api_url_notprotected : 'https://back-syop.grow-corporate.com/',
    // api_url_notprotected : 'https://back-pilotoso.grow-solutions.com/',
    // api_url_notprotected : 'http://127.0.0.1:8010/',
    basename: '/',
    produccion : false,
}