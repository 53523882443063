import { useSelector, useDispatch } from "react-redux"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Col, Row } from 'antd'
import { 
	CargaMasterSellIn, 
	UploadPricingMasterServices, 
	UploadProductMasterLastServices, 
	UploadProductMasterServices,
	UploadRealSellinServices,
	UploadSellOutServices,
	UploadMasterClientsServices,
	UploadBaselineServices,
	UploadMasterExChangeRateServices,
	UploadForecastServices,
	GetDataForecastServices,
} from "../../Redux/Services/FileUpload"
import CardFileUpload from '../../Components/FileUpload/CardFileUpload'
import CardNotification from '../../Components/FileUpload/CardNotification'
import './../../Styles/Routes/FileUpload.css'
import { useEffect } from "react"
import { getDataVersionBaselineServices, getDataVersionsFiltersServices, getDataVersionsServices } from "../../Redux/Services/Baseline"

const FileUpload = () => {

	const dispatch = useDispatch()

	const { rex_msgs_notificaciones } = useSelector(state => state.fileUpload)
	const { 
		rex_version_selected_baseline
	} = useSelector(state => state.baseline)

	const { isLoading: isLoadingForecast } = useQuery({
		queryKey: ['forecastServices'],
		queryFn : () => GetDataForecastServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})

	const uploadPricingMaster = useMutation({
        mutationFn: (fileUrl) => UploadPricingMasterServices(dispatch, rex_msgs_notificaciones,fileUrl),
    })

	const uploadRealSellin = useMutation({
		mutationFn: (fileUrl) => UploadRealSellinServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadProductMaster = useMutation({
		mutationFn: (fileUrl) => UploadProductMasterServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	// const uploadProductMasterLast = useMutation({
	// 	mutationFn: (fileUrl) => UploadProductMasterLastServices(dispatch, rex_msgs_notificaciones, fileUrl)
	// })

	const uploadClientMaster = useMutation({
		mutationFn: (fileUrl) => UploadMasterClientsServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadExChangeRate = useMutation({
		mutationFn: (fileUrl) => UploadMasterExChangeRateServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const cargaMasterSellIn = useMutation({
        mutationFn: (fileUrl) => CargaMasterSellIn(dispatch, rex_msgs_notificaciones, fileUrl),
	})

	const uploadSellOut = useMutation({
		mutationFn: (fileUrl) => UploadSellOutServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadBaseline = useMutation({
		mutationFn: (fileUrl) => UploadBaselineServices(dispatch, rex_msgs_notificaciones, fileUrl, null, rex_version_selected_baseline)
	})

	const uploadForecast = useMutation({
		mutationFn: ({fileUrl, valueForecast}) => UploadForecastServices(dispatch, rex_msgs_notificaciones, fileUrl, null, valueForecast)
	})

	const { isLoading: isLoadingVersions, data: response_vers } = useQuery({
		queryKey: ['versions'],
		queryFn : () => getDataVersionBaselineServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})

	return (
		<Row>
			<Col span={24} style={{ margin: '15px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Carga de Archivos</div>
			</Col>
			<Col span={18}>
				<Row gutter={[30, 30]}>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Plantilla baseline"}
							onAction={(fileUrl) => {
								uploadBaseline.mutate(fileUrl)
							}}
							isLoading={uploadBaseline.isPending}
							isSuccess={uploadBaseline.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Plantilla Sell In"}
							onAction={(fileUrl) => {
								uploadRealSellin.mutate(fileUrl)
							}}
							isLoading={uploadRealSellin.isPending}
							isSuccess={uploadRealSellin.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Plantilla Sell Out"}
							onAction={(fileUrl) => {
								uploadSellOut.mutate(fileUrl)
							}}
							isLoading={uploadSellOut.isPending}
							isSuccess={uploadSellOut.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Plantilla forecast"}
							onAction={(fileUrl, valueForecast) => {
								uploadForecast.mutate({fileUrl, valueForecast})
							}}
							isLoading={uploadForecast.isPending}
							isSuccess={uploadForecast.isSuccess}
						/>
					</Col>
					{/* <Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Master Pricing"}
							onAction={(fileUrl) => {
								uploadPricingMaster.mutate(fileUrl)
							}}
							isLoading={uploadPricingMaster.isPending}
							isSuccess={uploadPricingMaster.isSuccess}
						/>
					</Col> */}
					{/* <Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Sell In"}
							onAction={(fileUrl) => {
								cargaMasterSellIn.mutate(fileUrl)
							}}
							isLoading={cargaMasterSellIn.isPending}
							isSuccess={cargaMasterSellIn.isSuccess}
						/>
					</Col> */}
					{/* <Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Master Data Products"}
							onAction={(fileUrl) => {
								uploadProductMaster.mutate(fileUrl)
							}}
							isLoading={uploadProductMaster.isPending}
							isSuccess={uploadProductMaster.isSuccess}
						/>
					</Col> */}
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"MD materiales"}
							onAction={(fileUrl) => {
								uploadProductMaster.mutate(fileUrl)
							}}
							isLoading={uploadProductMaster.isPending}
							isSuccess={uploadProductMaster.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"MD clientes"}
							onAction={(fileUrl) => {
								uploadClientMaster.mutate(fileUrl)
							}}
							isLoading={uploadClientMaster.isPending}
							isSuccess={uploadClientMaster.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"MD tipo de cambio"}
							onAction={(fileUrl) => {
								uploadExChangeRate.mutate(fileUrl)
							}}
							isLoading={uploadExChangeRate.isPending}
							isSuccess={uploadExChangeRate.isSuccess}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={6}>
				<CardNotification />
			</Col>
		</Row>
	)
}

export default FileUpload